import React from "react";
import OuterNavBar from "../../layout/navbar/OuterNav";
import { Box, Container, Grid, useTheme } from "@mui/material";
import BannerImageAuth from "../../sections/auth/BannerImageAuth";
import LoginForm from "../../sections/auth/LoginForm";
import {
  CustomSubtitle,
  CustomTypography1,
} from "../../components/Typography/Typography";

function LoginPage() {
  const theme = useTheme();

  return (
    <Box>
      <OuterNavBar data={"New User?"} />
      <Box
        sx={{
          background: theme.palette.background.lightGrey,
          marginTop: "64px", // Adjust padding top to match navbar height
          paddingBottom: " 100px",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xl" sx={{paddingInline:"30px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}>
              <BannerImageAuth />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ mt: 10 }}>
                <CustomTypography1 sx={{fontSize:"32px",lineHeight:"42px"}}>
                  Welcome to Talks & Talks
                  <br />
                  Sign In to getting started.
                </CustomTypography1>
                <Box mt={2}>
                  <CustomSubtitle sx={{color:"rgba(255, 255, 255, 0.5)"}}>
                    Enter your details to proceed further
                  </CustomSubtitle>
                </Box>
                <Box mt={7} px={{ xs: 1, sm: 5, md: 5, lg: 8, xl: 10 }}>
                  <LoginForm />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default LoginPage;
