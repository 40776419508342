import React from 'react'
import { Outlet } from 'react-router-dom';
import SimpleNavbar from './navbar/SimpleNavbar';
// hooks
// import useOffSetTop from '../../hooks/useOffSetTop';
// config
// import { HEADER } from '../../config-global';
// components

// ----------------------------------------------------------------------

export default function SimpleLayout() {

  return (
    <>
     <SimpleNavbar/>
      <Outlet />
    </>
  );
}
