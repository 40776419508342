import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  header: "Overview",
  sideBar: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    darkMode: (state) => {
      state.mode = "dark";
    },
    lightMode: (state) => {
      state.mode = "light";
    },
    navHeader: (state, action) => {
      state.header = action.payload;
    },
    openSideBar: (state) => {
      state.sideBar = true;
    },
    closeSideBar: (state) => {
      state.sideBar = false;
    },
  },
});

export const { darkMode, lightMode, navHeader, openSideBar, closeSideBar } =
  themeSlice.actions;

export default themeSlice.reducer;
