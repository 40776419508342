// import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------

// SETUP COLORS

export const WHITE = {
  100: "var(--ffffff, #FFF);",
  50: "rgba(255, 255, 255, 0.15)",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

export const PRIMARY = {
  lighter: "#C8FACD",
  light: "#222526",
  main: "yellow",
  dark: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
  darker: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
  contrastText: "#fff",
};

export const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "var(--8083-a-3, #8083A3)",
  darker: "#091A7A",
};

export const colorTokens = {
  white: {
    100: "var(--ffffff, #FFF)",
    200: "#919EAB",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "rgba(255, 255, 255, 0.15)",
    900: "rgba(255, 255, 255, 0.50)",
  },
  grey: {
    100: "",
    200: "#222526",
    900: "var(--soft-a-1-a-1-a-1, rgba(161, 161, 161, 0.10));",
  },
  primary: {
    lighter: "#C8FACD",
    lightgrey: "#222526",
    main: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
    dark: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
    darker: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
    contrastText: "#fff",
  },
  secondary: {
    lighter: "#D6E4FF",
    light: "#84A9FF",
    main: "#3366FF",
    dark: "var(--8083-a-3, #8083A3)",
    darker: "#091A7A",
  },
  info: {
    main: "#0092F8",
  },
};

// Mui Theme Settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            //Palette values for dark mode
            primary: {
              dark: colorTokens.white[200],
              main: colorTokens.white[900],
              light: colorTokens.white[100],
              info: colorTokens.info.main,
              white: colorTokens.white[100],
              darkGrey: colorTokens.primary.dark,
            },
            // neutral: {
            //   dark: colorTokens.white[200],
            //   main: colorTokens.white[200],
            //   light: colorTokens.white[200],
            // },
            background: {
              default: colorTokens.primary.main,
              lightGrey: colorTokens.grey[200],
              light: colorTokens.white[100],
              dark: colorTokens.grey[900],
            },
          }
        : {
            primary: {
              dark: colorTokens.grey[200],
              main: colorTokens.white[900],
              light: colorTokens.white[100],
            },
            // neutral: {
            //   dark: colorTokens.white[200],
            //   main: colorTokens.white[200],
            //   light: colorTokens.white[200],
            // },
            background: {
              default: colorTokens.white[100],
              alt: colorTokens.white[100],
              light: colorTokens.white[100],
            },
          }),
    },
  };
};

// const INFO = {
//   lighter: '#CAFDF5',
//   light: '#61F3F3',
//   main: '#00B8D9',
//   dark: '#006C9C',
//   darker: '#003768',
//   contrastText: '#fff',
// };

// const SUCCESS = {
//   lighter: '#D8FBDE',
//   light: '#86E8AB',
//   main: '#36B37E',
//   dark: '#1B806A',
//   darker: '#0A5554',
//   contrastText: '#fff',
// };

// const WARNING = {
//   lighter: '#FFF5CC',
//   light: '#FFD666',
//   main: '#FFAB00',
//   dark: '#B76E00',
//   darker: '#7A4100',
// };

// const ERROR = {
//   lighter: '#FFE9D5',
//   light: '#FFAC82',
//   main: '#FF5630',
//   dark: '#B71D18',
//   darker: '#7A0916',
//   contrastText: '#fff',
// };
