//import * as React from 'react';
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  //border: '1px solid rgba(255, 255, 255, 0.15)', 
  backgroundColor: 'transparent', 

  border: '1px solid rgba(255, 255, 255, 0.15)', // Border for the unchecked state
 // backgroundColor: 'transparent', // No background color
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent', 
   // backgroundColor: 'transparent', // Keep no background color on hover
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    border: '1px solid rgba(206,217,224,.5)', // Border for disabled state
   // border: '1px solid rgba(206,217,224,.5)', // Border for disabled state
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  border: 'none', // Remove border for checked state
 // border: 'none', // Remove border for checked state
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
export function BpRadio(props) {
 const [selected, setSelected] = useState(false); //  whether the radio button is selected

  const handleToggle = () => {
    setSelected(!selected); // Toggle the selection state
  };
  
  return (
    <Radio
    disableRipple
    color="default"
    checked={selected} // Set the selected state
    onClick={handleToggle} // Handle click event to toggle selection
    checkedIcon={
      <BpCheckedIcon
        sx={{
          width: "20px",
          height: "20px",
          color: "white", // Ensure the white color for the checked icon
          fontSize: "20px", // Adjust icon size as needed
          display: "flex",
          alignItems: "center", // Align icon vertically
          justifyContent: "center", // Align icon horizontally
        }}
      />
    }
    icon={
      <BpIcon
        sx={{
          color: "white", // Ensure the white color for the default icon
          fontSize: "20px", // Adjust icon size as needed
          display: "flex",
          alignItems: "center", // Align icon vertically
          justifyContent: "center", // Align icon horizontally
        }}
      />
    }
    {...props}
  />
  
  );
}

export default function CustomizedRadios() {
  return (
    <FormControl>
      <FormLabel id="demo-customized-radios">Gender</FormLabel>
      <RadioGroup
        defaultValue="female"
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
      >
        <FormControlLabel value="female" control={<BpRadio />} label="Female" />
        <FormControlLabel value="male" control={<BpRadio />} label="Male" />
        <FormControlLabel value="other" control={<BpRadio />} label="Other" />
        <FormControlLabel
          value="disabled"
          disabled
          control={<BpRadio />}
          label="(Disabled option)"
        />
      </RadioGroup>
    </FormControl>
  );
}
