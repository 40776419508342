import React,{ Suspense, lazy } from 'react';
// components
import LoadingPage from '../pages/LoadingPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => {
  const WrappedComponent = (props) => (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );
  // Set display name for debugging purposes
  WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;
  return WrappedComponent;
};

// ----------------------------------------------------------------------

// AUTH
export const SignUpPage = Loadable(lazy(() => import('../pages/auth/SignUpPage')));
export const LoginPage = Loadable(lazy(() =>import('../pages/auth/LoginPage')))
export const ResetPasswordPage = Loadable(lazy(()=> import('../pages/auth/PasswordReset')))
export const EmailStatusPage = Loadable(lazy(()=> import('../pages/auth/EmailStatusPage')))
export const ForgetPasswordPage = Loadable(lazy(()=> import('../pages/auth/ForgetPassword')))
export const ForgetPasswordEmailPage = Loadable(lazy(()=> import('../pages/auth/PasswordRestSuccessPage')))
export const OtpVerificationPage = Loadable(lazy(()=> import('../pages/auth/OtpVerification')))
export const PromoCodePage = Loadable(lazy(()=> import('../pages/auth/PromocodePage')))
export const GuestLoginPage = Loadable(lazy(()=> import('../pages/auth/GuestLogin')))
export const SocialMediaSignUpSuccessPage = Loadable(lazy(()=> import('../pages/auth/SocialMediaSignUpSuccess')))
export const ForgetPasswordResetSuccess = Loadable(lazy(()=> import('../pages/auth/PasswordRestSuccessPage')))


//DASHBOARD
export const DashBoardPage = Loadable(lazy(()=> import('../pages/Dashboard/DashBoardPage')))
export const MessagePage = Loadable(lazy(()=> import('../pages/messenger/Messeges')))
export const SettingsPage = Loadable(lazy(()=> import('../pages/settings/SettingsPage')))
export const SchedulePage  = Loadable(lazy(()=> import('../pages/Schedule')))
export const NotificationPage  = Loadable(lazy(()=> import('../pages/Notifications/index')))
export const ContactPage = Loadable(lazy(()=> import('../pages/Contacts/Contact'))) //
export const MeetingJoinPage = Loadable(lazy(()=> import('../pages/Call/MeetingJoin')))
export const ParticipantsPage = Loadable(lazy(()=> import('../pages/Call/ParticipantsPage')))
export const DocumentsPage = Loadable(lazy(()=> import('../pages/Documents/DocumentsPage')))
export const FaqPage = Loadable(lazy(()=> import('../pages/Faq/FaqPage')))

// CALL
export const CallHistoryPage = Loadable(lazy(()=> import('../pages/Call')))
export const VideoCallPage =Loadable(lazy(()=> import('../pages/Call/VideoCallPage')))
export const MeetingNotStartPage =Loadable(lazy(()=> import('../pages/Call/MeetingNotStart')))
export const CreateMeetingPage =Loadable(lazy(()=> import('../sections/call/CreateMeeting')))

//ADMINDASHBOARD
export const AdminDashboardpage = Loadable(lazy(()=> import('../pages/admin/AdminDashboard'))) 