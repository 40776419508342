import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { WHITE } from "../../theme/Palette";

export const CustomTextField = styled(TextField)(() => ({
  color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "800",
  lineHeight: "normal",

  "& .MuiInput-underline:before": {
    borderBottom: `2px solid ${WHITE[50]}`, // Custom border before focus
  },
  "& .MuiInput-underline:after": {
    borderBottom: `2px solid ${WHITE[50]}`, // Custom border after focus
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: `2px solid ${WHITE[50]}`, // Custom border on hover
  },
  "& .MuiInputBase-input": {
    padding: "12px 0",
    color: "white", // Ensure text color is white
  },
}));
