import React from "react";
import { Box } from "@mui/material";
import {
  CustomSubtitle,
  CustomTypography1,
  CustomTypography4,
} from "../../components/Typography/Typography";
import { CustomOutlinedButton } from "../../components/Butons/CustomButton";
import BannerImage from "../../assets/images/Login/BannerImageLogin.svg";

function BannerImageAuth() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        // margin:"auto",
        marginTop: {
          xs: "1rem", // Full width on extra small screens
          sm: "1rem", // Full width on small screens
          md: "3rem",
          lg: "4rem", // Full width on medium screens
          xl: "4rem",
        },
      }}
    >
      <Box
      sx={{
        width: "100%",
        height: "auto", // Ensures the aspect ratio is maintained
        maxWidth: {
          xs: "100%", // Full width on extra small screens
          sm: "100%", // Full width on small screens
          md: "100%",
          lg: "650px",  // Full width on medium screens
          xl: "700px", // 800px max width on large screens
        },
        overflow: "hidden",
        img: {
          width: "100%",
          height: "auto",
        },
      }}
      >
        <img
          src={BannerImage}
          alt="Banner Image"
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "500px",
          flexDirection: "column",
        }}
      >
        <Box mt={5}>
          <CustomTypography1>
            Meeting without,
            <br /> Worrying about Language
          </CustomTypography1>
        </Box>
        <Box mt={2}>
          <CustomSubtitle variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur. Sit est pharetra eget
            vehicula. Dui cursus erat nunc amet gravida amet.
          </CustomSubtitle>
        </Box>
        <Box mt={4}>
          <CustomOutlinedButton sx={
            {":hover":{
              background: "var(--f-5-f-5-fa-40, rgba(245, 245, 250, 0.40))",
            }}
          }>
            <CustomTypography4> Learn More</CustomTypography4>
          </CustomOutlinedButton>
        </Box>
      </Box>
    </Box>
  );
}

export default BannerImageAuth;
