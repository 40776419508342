import React from 'react';
import { CircularProgress, Box ,useTheme} from '@mui/material';


const LoadingPage = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: theme.palette.background.default
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingPage;
