// src/features/drawer/drawerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDrawer: false,
  sideBox:"",
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.openDrawer = true;
    },
    closeDrawer: (state) => {
      state.openDrawer = false;
    },
    openSideBox: (state,action) => {
      state.sideBox = action.payload;
    },
    closeSideBox: (state,action) => {
      state.sideBox = action.payload;
    },
  },
});

export const { openDrawer, closeDrawer, openSideBox, closeSideBox } =
  drawerSlice.actions;

export default drawerSlice.reducer;
