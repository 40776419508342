import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import AuthSliceReducer from "./slices/AuthSlice";
import DrawerSliceReducer from "./slices/DrawerSlice";
import ThemeSliceReducer from "./slices/ThemeSlice";
import UserSliceReducer from "./slices/UserSlice";
import MeetingSliceReducer from "./slices/MeetingSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "theme"], // List the reducers you want to persist
};
const rootReducer = combineReducers({
  auth: AuthSliceReducer,
  drawer: DrawerSliceReducer,
  theme: ThemeSliceReducer,
  user: UserSliceReducer,
  meeting: MeetingSliceReducer,
});

export { rootPersistConfig };
export default rootReducer;
