import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  flexShrink: 0,
  textTransform: "none",
  borderRadius: "10px",
  background: " #434647",
  
  "&:hover": {
    backgroundColor: theme.palette.background.info,
    color: "#fff",
    boxShadow: "none",
  },
}));

export const CustomOutlinedButton = styled(Button)(() => ({
  borderRadius: "4px",
  border: "1px solid var(--Light-theme-Rest-Border-Default-Border, #D1D1D1)",
  color: "#fff",
  textTransform: "none",
  background: "var(--f-5-f-5-fa-40, rgba(245, 245, 250, 0.40))",

}));

export const OutlinedButton = styled(Button)(() => ({
  borderRadius: "8px",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  background: "var(--222526, #222526)",
  color: "#fff",
  textTransform: "none",
}));

export const CloseButton = styled(IconButton)(() => ({
  // position: "absolute",
  // right: theme.spacing(1),
  // top: theme.spacing(1),
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: "30%",
  color: "rgba(255, 255, 255, 0.15)",
}));
