import axios from "axios";
import { HOST_API_KEY } from "./Config-globals";

export const API = axios.create({
  baseURL: HOST_API_KEY,
});

// Function to refresh the access token
const refreshToken = async () => {
  try {
    const refresh = localStorage.getItem("refresh_token");
    if (!refresh) {
      throw new Error("No refresh token available");
    }

    const response = await axios.post(`${HOST_API_KEY}/auth/token/refresh/`, {
      refresh,
    });

    const { access } = response.data;
    localStorage.setItem("access_token", access);
    return access;
  } catch (error) {
    console.error("Unable to refresh token", error);
    throw error;
  }
};

// Request interceptor to add the access token to headers
API.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration and refreshing
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return API(originalRequest);
      } catch (refreshError) {
        // If refresh fails, redirect to login page
        console.error("Token refresh failed", refreshError);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/auth/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default API;

