import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Divider, Stack, MenuItem, Avatar } from "@mui/material";

// routes
// import { PATH_AUTH } from "../routes/Path";
// auth

import MenuPopover from "../components/menu-popover/MenuPopover";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/AuthSlice";
import { navHeader } from "../redux/slices/ThemeSlice";
import {
  CustomSubtitle,
  CustomTypography4,
} from "../components/Typography/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import PassWordChange from "../assets/icons/profile/encrypted.svg";
import ManageSub from "../assets/icons/profile/stars.svg";
import EditProfile from "../assets/icons/profile/person_edit.svg";
import Logout from "../assets/icons/profile/move_item.svg";

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: "Manage Subscription",
    img: ManageSub,
    linkTo: '/dashboard/settings',
  },
  {
    label: "Change Password",
    img: PassWordChange,
    linkTo:'/dashboard/settings',
  },

  {
    label: "Edit Profile",
    img: EditProfile,
    linkTo: '/dashboard/settings',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ openPopover, setOpenPopover }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //   const { user, logout } = useAuthContext();

  //   const { enqueueSnackbar } = useSnackbar();

  //   const [openPopover, setOpenPopover] = useState(true);

  //   const handleOpenPopover = (event) => {
  //     setOpenPopover(event.currentTarget);
  //   };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleLogout = async () => {
    try {
      // Dispatch the logout action to clear Redux state
      dispatch(logout());
      
      // Dispatch to update the navigation header state
      dispatch(navHeader("Overview"));
      
      // Clear the local storage and session storage
      localStorage.clear();
      sessionStorage.clear();
  
      // Navigate to the auth/login path
      navigate("/auth/login", { replace: true });
  
      // Close the popover menu
      handleClosePopover();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };  

  const handleClickItem = (path, label) => {
    handleClosePopover();
  
    // Update `localStorage` first
    localStorage.removeItem("settings");
  
    if (label === "Manage Subscription") {
      localStorage.setItem("settings", "manageyourplan");
      dispatch(navHeader("Settings"));
    } else if (label === "Change Password") {
      localStorage.setItem("settings", "changePassword");
      dispatch(navHeader("Settings"));
    } else if (label === "Edit Profile") {
      localStorage.setItem("settings", "editprofile");
      dispatch(navHeader("Settings"));
    }
  
    // Navigate to the settings page
    navigate(path, { replace: true });
  };
  

  const email = localStorage.getItem('email');
  const username = localStorage.getItem('user_name');
  
  const user = {
    displayName: username,
    email: email,
  };

  return (
    <>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{
          width: "fit-",
          p: 2,
          background: "var(--222526, #222526)",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            display: "flex",
            justifyContent: "left",
            gap: 2,
          }}
        >
          <Avatar variant="rounded" src={user?.profile}></Avatar>
          <Box>
            <CustomTypography4>{user?.displayName}</CustomTypography4>

            <CustomSubtitle>{user?.email}</CustomSubtitle>
          </Box>
        </Box>

        <Stack sx={{ p: 1 }}>
          <MenuItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomTypography4>Set Status</CustomTypography4>
            <KeyboardArrowRightIcon />
          </MenuItem>
          <Divider />
          {OPTIONS.map((option) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
              key={option.label}
            >
              <MenuItem
                key={option.label}
                onClick={() => handleClickItem(option.linkTo,option.label)}
              >
                <img src={option.img} style={{ marginRight: "10px" }} />
                <CustomTypography4>{option.label}</CustomTypography4>
              </MenuItem>
              <KeyboardArrowRightIcon />
            </Box>
          ))}

          <MenuItem onClick={handleLogout}>
            <img src={Logout} style={{ marginRight: "10px" }} />
            <CustomTypography4>Logout</CustomTypography4>
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}

AccountPopover.propTypes = {
  openPopover: PropTypes.string,
  setOpenPopover: PropTypes.func,
};
