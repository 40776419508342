import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MeetingRoomIcon from "../../assets/icons/logos/meetingroomIcon.svg";

function SimpleNavbar() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "64px",
        width: "100%",
        // background: "red",
        alignItems: "center",
        paddingInline: "20px",
      }}
    >
      <img width="50px" src={MeetingRoomIcon} />
      <IconButton
        sx={{ background: theme.palette.background.dark, borderRadius: "8px" }}
      >
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

export default SimpleNavbar;
