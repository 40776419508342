import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTypography1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "38px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px", // Adjust font size for small screens
    lineHeight: "32px", // Adjust line height for small screens
  },
}));

export const CustomTypography2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "42px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px", // Adjust font size for small screens
    lineHeight: "32px", // Adjust line height for small screens
  },
}));
export const CustomTypography3 = styled(Typography)(() => ({
  color: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
  fontfeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "38px",
}));

export const CustomTypography4 = styled(Typography)(() => ({
  color: "var(--ffffff, #FFF)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineheight: "19.2px",
  
}));

export const CustomSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "21px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
    lineHeight: "18px",
  },
}));

export const CustomSubtitle2 = styled(Typography)(({ theme }) => ({
  color: "var(--6-b-59-cc-primary, #0092F8);",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
}));
export const CustomSubtitle3 = styled(Typography)(() => ({
  color: "var(--8083-a-3, #8083A3)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "21px",
}));
